<template>
	<modal class="NoxModalUsers" name="NoxModalUsers" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_open_loading" v-if="noxIsOpenLoading"><div class="nox_loading"></div></div>
			<div class="nox_modal_open" v-else>
				<div class="nox_modal_head">
					<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
					<div class="nox_modal_title">
						<span v-if="noxType == 'getUser'">Просмотр данных пользователя</span>
						<span v-else-if="noxType == 'editUser'">Редактирование данных пользователя</span>
					</div>
				</div>
				<div class="nox_modal_body">
					<div class="nox_modal_info">
						<div v-if="noxType == 'getUser'">
							<div class="nox_modal_info_user_data">
								<table class="nox_table mini" v-if="Object.keys(noxUserData).length">
									<tbody>
										<tr><td data-label="NOX ID:">{{ noxUserData.id }}</td></tr>
										<tr><td data-label="NOX ID спонсора:">{{ noxUserData.sponsor_id }}</td></tr>
										<tr><td data-label="Email:">{{ noxUserData.email }}</td></tr>
										<tr><td data-label="Телефон:">{{ noxUserData.phone }}</td></tr>
										<tr><td data-label="Псевдоним:">{{ noxUserData.username }}</td></tr>
										<tr><td data-label="Имя у брокера:">{{ noxUserData.fullname }}</td></tr>
										<tr><td data-label="Страна:">{{ noxUserData.country }} ({{ noxCountries[noxUserData.country] }})</td></tr>
										<tr><td data-label="Язык:">{{ noxUserData.language }}</td></tr>
										<tr><td data-label="Аватар:"><a :href="noxUserData.avatar" target="_blank" v-if="noxUserData.avatar">{{ noxUserData.avatar }}</a></td></tr>
										<tr><td data-label="Группа:" v-html="$store.getters.getLanguageText('4.3.2', noxUserData.group)"></td></tr>
										<tr><td data-label="Статус:" v-html="$store.getters.getLanguageText('4.3.1', noxUserData.status)"></td></tr>
										<tr><td data-label="Рейтинг:">{{ noxUserData.rating }}</td></tr>
										<tr><td data-label="Telegram ID:">{{ noxUserData.telegram_id }}</td></tr>
										<tr><td data-label="Основной баланс:">{{ $parent.$parent.$parent.getNumberFormatForDollar(noxUserData.balance_nox, 2) }}</td></tr>
										<tr><td data-label="Бонусный баланс:">{{ $parent.$parent.$parent.getNumberFormatForDollar(noxUserData.balance_of_bonuses, 2) }}</td></tr>
										<tr><td data-label="Баланс NOX ZP:">{{ $parent.$parent.$parent.getNumberFormatForDollar(noxUserData.balance_nox_zp, 2) }}</td></tr>
										<tr><td data-label="Текущий пакет NOX:">{{ $store.state.noxSystemSettings['package_name_x' + noxUserData.package] }}</td></tr>
										<tr><td data-label="Дата покупки пакета NOX:">{{ (noxUserData.package_buy_at ? new Date(noxUserData.package_buy_at * 1000).toLocaleString($store.state.noxLanguage) : '') }}</td></tr>
										<tr><td data-label="Дата завершения абонентской платы аккаунта:">{{ (noxUserData.package_at ? new Date(noxUserData.package_at * 1000).toLocaleString($store.state.noxLanguage) : '') }}</td></tr>
										<tr><td data-label="Реферальная ссылка Forex4you:">{{ noxUserData.forex4you }}</td></tr>
										<tr><td data-label="Реферальная ссылка Forex Box:">{{ noxUserData.forex_box }}</td></tr>
										<tr><td data-label="Ссылка на профиль ВКонтакте:">{{ noxUserData.vk }}</td></tr>
										<tr><td data-label="Ссылка на профиль Instagram:">{{ noxUserData.instagram }}</td></tr>
										<tr><td data-label="Ссылка на профиль Facebook:">{{ noxUserData.facebook }}</td></tr>
										<tr><td data-label="Номер в WhatsApp:">{{ noxUserData.whatsapp }}</td></tr>
										<tr><td data-label="Логин в Telegram:">{{ noxUserData.telegram }}</td></tr>
										<tr><td data-label="Логин в Skype:">{{ noxUserData.skype }}</td></tr>
										<tr><td data-label="Id закрытых splash уведомлений:">{{ noxUserData.splash_ids }}</td></tr>
										<tr><td data-label="Дата закрытия splash уведомления:">{{ (noxUserData.splash_at ? new Date(noxUserData.splash_at * 1000).toLocaleString($store.state.noxLanguage) : '') }}</td></tr>
										<tr><td data-label="Данные клиентской программы:">{{ (Object.keys(noxUserData.tree_details).length ? noxUserData.tree_details : '') }}</td></tr>
										<tr><td data-label="Количество обращений к конфигурации NOX VPN:">{{ noxUserData.vpn_get_configuration_count }}</td></tr>
										<tr><td data-label="Дата последнего обращения к конфигурации NOX VPN:">{{ (noxUserData.vpn_get_configuration_at ? new Date(noxUserData.vpn_get_configuration_at * 1000).toLocaleString($store.state.noxLanguage) : '') }}</td></tr>
										<tr><td data-label="Активирован?">{{ (noxUserData.active ? 'Да' : 'Нет') }}</td></tr>
										<tr><td data-label="Верифицирован?">{{ (noxUserData.is_verification ? 'Да' : 'Нет') }}</td></tr>
										<tr><td data-label="Режим инкогнито?">{{ (noxUserData.is_incognito ? 'Да' : 'Нет') }}</td></tr>
										<tr><td data-label="Забанен?">{{ (noxUserData.is_banned ? 'Да' : 'Нет') }}</td></tr>
										<tr><td data-label="KYC?">{{ (noxUserData.is_kyc ? 'Да' : 'Нет') }}</td></tr>
										<tr><td data-label="Серый аккаунт?">{{ (noxUserData.is_grey ? 'Да' : 'Нет') }}</td></tr>
										<tr><td data-label="Коронный серый аккаунт?">{{ (noxUserData.is_greys ? 'Да' : 'Нет') }}</td></tr>
										<tr><td data-label="С абонентской платой аккаунта?">{{ (noxUserData.is_subscription ? 'Да' : 'Нет') }}</td></tr>
										<tr><td data-label="С автопродлением абонентской платы аккаунта?">{{ (noxUserData.is_subscription_auto ? 'Да' : 'Нет') }}</td></tr>
										<tr><td data-label="Количество перков:">{{ noxUserData.count_of_perks }}</td></tr>
										<tr><td data-label="Количество добавленных ботов:">{{ noxUserData.count_add_bots }}</td></tr>
										<tr><td data-label="Количество купленных ботов:">{{ noxUserData.count_buy_bots }}</td></tr>
										<tr><td data-label="Количество запущенных ботов:">{{ noxUserData.count_start_bots }}</td></tr>
										<tr><td data-label="Количество купленных серверов:">{{ noxUserData.count_buy_servers }}</td></tr>
										<tr><td data-label="Количество активных сайтов:">{{ noxUserData.count_of_active_websites }}</td></tr>
										<tr><td data-label="Количество приглашенных пользователей:">{{ noxUserData.count_of_invited_users }}</td></tr>
										<tr><td data-label="Количество активных приглашенных пользователей:">{{ noxUserData.count_of_active_invited_users }}</td></tr>
										<tr><td data-label="Максимальное количество закрытых циклов за неделю:">{{ noxUserData.max_count_of_cycles_per_week }}</td></tr>
										<tr><td data-label="Дата создания аккаунта:">{{ (noxUserData.created_at ? new Date(noxUserData.created_at * 1000).toLocaleString($store.state.noxLanguage) : '') }}</td></tr>
										<tr><td data-label="Дата изменения аккаунта:">{{ (noxUserData.updated_at ? new Date(noxUserData.updated_at * 1000).toLocaleString($store.state.noxLanguage) : '') }}</td></tr>
									</tbody>
								</table>
							</div>
						</div>
						<div v-else-if="noxType == 'editUser'">
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label">Email:</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxUserData.email" placeholder="укажите email" autocomplete="off">
									<div v-html="noxAlertEmail"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label">Телефон:</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxUserData.phone" placeholder="укажите телефон" autocomplete="off">
									<div v-html="noxAlertPhone"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label">Псевдоним:</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxUserData.username" placeholder="укажите псевдоним" autocomplete="off">
									<div v-html="noxAlertUsername"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label">Имя у брокера:</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxUserData.fullname" placeholder="укажите имя у брокера" autocomplete="off">
									<div v-html="noxAlertFullname"></div>
								</div>
							</div>
							<div class="nox_modal_info_row">
								<div class="nox_modal_info_row_label">Страна:</div>
								<div class="nox_modal_info_row_value">
									<v-select class="nox_select style-chooser" v-model.trim="noxCountry" placeholder="выберите страну" :options="$parent.$parent.$parent.getSelectOptions(1)"></v-select>
									<div v-html="noxAlertCountry"></div>
								</div>
							</div>
							<div class="nox_modal_info_row last">
								<div class="nox_modal_info_row_label">Telegram ID:</div>
								<div class="nox_modal_info_row_value">
									<input type="text" class="nox_input" v-model.trim="noxUserData.telegram_id" placeholder="укажите telegram id" autocomplete="off">
									<div v-html="noxAlertTelegramId"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="nox_modal_alert" v-html="noxAlert"></div>
					<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
						<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
						<button type="button" class="nox_button common green" @click="axios('editUser')" v-if="noxType == 'editUser'" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
					</div>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertEmail: '',
			noxAlertPhone: '',
			noxAlertUsername: '',
			noxAlertFullname: '',
			noxAlertCountry: '',
			noxAlertTelegramId: '',
			noxId: 0,
			noxType: '',
			noxIndex: 0,
			noxUserData: {},
			noxCountry: '',
			noxCountries: {},
			noxIsOpenLoading: false,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxUserData = {};
				this.noxCountry = '';
				this.noxCountries = this.$parent.$parent.$parent.getCountries();
				this.noxIsOpenLoading = false;
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (['getUser', 'editUser'].includes(this.noxType)) { this.axios('getUser'); }
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertEmail = '';
				this.noxAlertPhone = '';
				this.noxAlertUsername = '';
				this.noxAlertFullname = '';
				this.noxAlertCountry = '';
				this.noxAlertTelegramId = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = this.$store.getters.getLanguageText('1.3.21', 0); }
				else if (i == 2) { this.noxTemp = this.$store.getters.getLanguageText('1.3.21', 1); }
				else if (i == 3) { this.noxTemp = this.$store.getters.getLanguageText('1.3.21', 2); }
				else if (i == 4) { this.noxTemp = this.$store.getters.getLanguageText('1.3.21', 3); }
				else if (i == 5) { this.noxTemp = this.$store.getters.getLanguageText('1.3.21', 4); }
				else if (i == 6) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 4); }
				else if (i == 7) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 5); }
				else if (i == 8) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 7); }
				else if (i == 9) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 8); }
				else if (i == 10) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 9); }
				else if (i == 11) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 11); }
				else if (i == 12) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 12); }
				else if (i == 13) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 13); }
				else if (i == 14) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 21); }
				else if (i == 15) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 22); }
				else if (i == 16) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 53); }
				else if (i == 17) { this.noxTemp = this.$store.getters.getLanguageText('1.3.1', 54); }
				else if (i == 18) { this.noxTemp = this.$store.getters.getLanguageText('1.3.9', 0); }
				else if (i == 19) { this.noxTemp = this.$store.getters.getLanguageText('1.3.9', 1); }
				else              { this.noxTemp = this.$store.getters.getLanguageText('1.3', 2); }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function(type) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (type == 'getUser') {
						_this.noxIsOpenLoading = true;
						config.url = '/v2/admin/account/users/info';
						config.params = { id: _this.noxId };
					}
					else if (type == 'editUser') {
						if (!_this.noxUserData.email) {
							_this.noxAlertEmail = _this.getError(6);
						}
						else if (!_this.$store.state.noxRegexEmail.test(_this.noxUserData.email)) {
							_this.noxAlertEmail = _this.getError(7);
						}
						if (!_this.noxUserData.phone) {
							_this.noxAlertPhone = _this.getError(9);
						}
						else if (!_this.$store.state.noxRegexPhone.test(_this.noxUserData.phone)) {
							_this.noxAlertPhone = _this.getError(10);
						}
						if (!_this.noxUserData.username) {
							_this.noxAlertUsername = _this.getError(12);
						}
						else if (!_this.$store.state.noxRegexUsername.test(_this.noxUserData.username)) {
							_this.noxAlertUsername = _this.getError(13);
						}
						if (_this.noxUserData.fullname && !_this.$store.state.noxRegexENLettersAndSpace.test(_this.noxUserData.fullname)) {
							_this.noxAlertFullname = _this.getError(17);
						}
						if (!_this.noxCountry) {
							_this.noxAlertCountry = _this.getError(14);
						}
						else if (_this.noxCountry.code == undefined) {
							_this.noxAlertCountry = _this.getError(15);
						}
						if (_this.noxUserData.telegram_id && !Number(_this.noxUserData.telegram_id)) {
							_this.noxAlertTelegramId = _this.getError(19);
						}
						if (!_this.noxIsError) {
							_this.noxIsLoading = true;
							_this.noxUserData.country = _this.noxCountry.code;
							config.url = '/v2/admin/account/users/info';
							config.data = { data: _this.noxUserData };
							config.method = 'patch';
						}
					}

					if (!_this.noxIsError) {
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (type == 'getUser') {
									data.data.phone = ((data.data.phone ? '+' : '') + data.data.phone);
									data.data.whatsapp = ((data.data.whatsapp ? '+' : '') + (data.data.whatsapp ? data.data.whatsapp : ''));
									data.data.telegram_id = (data.data.telegram_id ? data.data.telegram_id : 0);
									_this.noxUserData = data.data;
									_this.$parent.noxUsersData.splice(_this.noxIndex, 1, data.data);
									if (_this.noxType == 'editUser') {
										for (var i in _this.$parent.$parent.$parent.getSelectOptions(1)) {
											if (_this.$parent.$parent.$parent.getSelectOptions(1)[i].code == _this.$parent.noxUsersData[_this.noxIndex].country) {
												_this.noxCountry = _this.$parent.$parent.$parent.getSelectOptions(1)[i]; break;
											}
										}
									}
								}
								else if (type == 'editUser') { _this.close(); }
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (['getUser', 'editUser'].includes(type)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'DATA_IS_EMPTY') { _this.noxAlert = _this.getError(4); }
										else if (data.response.data.error == 'DATA_NOT_VALID') { _this.noxAlert = _this.getError(5); }
										else if (data.response.data.error == 'EMAIL_IS_EMPTY') { _this.noxAlertEmail = _this.getError(6); }
										else if (data.response.data.error == 'EMAIL_NOT_VALID') { _this.noxAlertEmail = _this.getError(7); }
										else if (data.response.data.error == 'PHONE_IS_EMPTY') { _this.noxAlertPhone = _this.getError(9); }
										else if (data.response.data.error == 'PHONE_NOT_VALID') { _this.noxAlertPhone = _this.getError(10); }
										else if (data.response.data.error == 'USERNAME_IS_EMPTY') { _this.noxAlertUsername = _this.getError(12); }
										else if (data.response.data.error == 'USERNAME_NOT_VALID') { _this.noxAlertUsername = _this.getError(13); }
										else if (data.response.data.error == 'FULLNAME_IS_EMPTY') { _this.noxAlertFullname = _this.getError(16); }
										else if (data.response.data.error == 'FULLNAME_NOT_VALID') { _this.noxAlertFullname = _this.getError(17); }
										else if (data.response.data.error == 'COUNTRY_IS_EMPTY') { _this.noxAlertCountry = _this.getError(14); }
										else if (data.response.data.error == 'COUNTRY_NOT_VALID') { _this.noxAlertCountry = _this.getError(15); }
										else if (data.response.data.error == 'TELEGRAM_ID_IS_EMPTY') { _this.noxAlertTelegramId = _this.getError(18); }
										else if (data.response.data.error == 'TELEGRAM_ID_NOT_VALID') { _this.noxAlertTelegramId = _this.getError(19); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (['getUser', 'editUser'].includes(type)) {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(3); }
									}
								}
								else if (data.response.status == 409) {
									if (type == 'editUser') {
										if      (data.response.data.error == 'EMAIL_DUPLICATE') { _this.noxAlertEmail = _this.getError(8); }
										else if (data.response.data.error == 'PHONE_DUPLICATE') { _this.noxAlertPhone = _this.getError(11); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsOpenLoading = false;
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('NoxModalUsers');
			}
		}
	}
</script>
